<template>
  <div>
    <b-modal
      v-model="modal"
      id="modal-xl"
      size="xl"
      title="Regitstrando Pagamento Fornecedor"
      hide-footer
      @hidden="fechar_financeiro()"
    >
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div
                class="row d-flex justify-content-right align-items-right mb-4"
              >
                <div class="col-md-3 text-left">
                  <label for="">Fornecedor:</label>
                  <input type="text" class="form-control text-left" />
                </div>
                <div class="col-md-3 text-left">
                  <label for="">Periodo:</label>
                  <input type="date" class="form-control text-left" />
                </div>
                <div class="col-md-3 text-center">
                  <label for="">&emsp;</label>
                  <input type="date" class="form-control text-left" />
                </div>
                <div class="col-md-3 text-right">
                  <br />
                  <b-dropdown
                    id="dropdown-dropup"
                    dropup
                    text="Opções"
                    variant="secondary"
                    class="m-2"
                  >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-edit mr-2"></i>Registrar
                      Pagamento</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="abrir_opc()"
                      ><i class="fas fa-edit mr-2"></i>Faturamenot de
                      Serviço</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-edit mr-2"></i>Destinar Saldo
                      Selecionado</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-edit mr-2"></i>ir para
                      Cadastro</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-edit mr-2"></i>ir para Contas a
                      Pagar</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-edit mr-2"></i>ir para Listar
                      Compras</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-eye mr-2"></i>imprimir Conta
                      Corrente</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
              <b-tabs card>
                <b-tab title="Conta Corrente" active>
                  <div class="row">
                    <div class="col-md-12">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th>&nbsp;</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <tr>
                            <td>
                              <b-form-checkbox value="3">
                                Selecionar todos
                              </b-form-checkbox>
                            </td>
                          </tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="objetoFornecedor.conta"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_corrente"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                        <template #cell(pessoa_id)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          {{ row.item.pessoa.pes_apelido }}
                        </template>
                      </b-table>
                      <div class="row">
                        <div class="col-md-12">
                          <h5
                            v-if="
                              objetoFornecedor.conta[0].transferencias_destino
                                .length > 0
                            "
                          >
                            Transferências feitas para essa conta
                          </h5>
                          <b-table
                            v-if="
                              objetoFornecedor.conta[0].transferencias_destino
                                .length > 0
                            "
                            head-variant="dark"
                            striped
                            hover
                            :items="
                              objetoFornecedor.conta[0].transferencias_destino
                            "
                            responsive
                            id="doc-itens"
                            :fields="fields_transf_dest"
                          >
                            <template #cell(check)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              <b-form-checkbox
                                value="3"
                                v-model="row.item.check"
                              >
                              </b-form-checkbox>
                            </template>
                            <template #cell(origem_id)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ getContas(row.item.origem_id) }}
                            </template>
                            <template #cell(destino_id)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ getContas(row.item.destino_id) }}
                            </template>
                            <template #cell(valor)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ formatterCurrBR(row.item.valor) }}
                            </template>
                            <template #cell(data)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ formatDateBR(row.item.data) }}
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <h5
                            v-if="
                              objetoFornecedor.conta[0].transferencias_origem
                                .length > 0
                            "
                          >
                            Transferencias Realizadas Por essa Conta
                          </h5>
                          <b-table
                            v-if="
                              objetoFornecedor.conta[0].transferencias_origem
                                .length > 0
                            "
                            head-variant="dark"
                            striped
                            hover
                            :items="
                              objetoFornecedor.conta[0].transferencias_origem
                            "
                            responsive
                            id="doc-itens"
                            :fields="fields_transf_dest"
                          >
                            <template #cell(check)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              <b-form-checkbox
                                value="3"
                                v-model="row.item.check"
                              >
                              </b-form-checkbox>
                            </template>
                            <template #cell(origem_id)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ getContas(row.item.origem_id) }}
                            </template>
                            <template #cell(valor)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ formatterCurrBR(row.item.valor) }}
                            </template>
                            <template #cell(data)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ formatDateBR(row.item.data) }}
                            </template>
                            <template #cell(destino_id)="row">
                              <!-- <span>{{row.item.vecimento}}</span> -->
                              {{ getContas(row.item.destino_id) }}
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Contas a Pagar">
                  <div class="row">
                    <div class="col-md-12">
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_contas_a_pagar"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="pedidos">
                  <div class="row">
                    <div class="col-md-12">
                      <Label
                        ><b-badge
                          data-name="Total"
                          class="field-status"
                          variant="danger"
                          >{{ "Pedidos com Alguma pendencia" }}</b-badge
                        ></Label
                      >
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_pedidos"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_pedidos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                      </b-table>
                    </div>
                    <div class="col-md-12">
                      <Label
                        ><b-badge
                          data-name="Total"
                          class="field-status"
                          variant="success"
                          >{{ "Pedidos Concluidos de : " }}</b-badge
                        ></Label
                      >
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_pedidos"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_pedidos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Faturamentos">
                  <div class="row">
                    <div class="col-md-12">
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_faturamentos"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_faturamento"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Adiantamentos">
                  <div class="row">
                    <div v-if="recarega_tabela" class="col-md-12">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th>&nbsp;</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <tr>
                            <td>
                              <b-form-checkbox
                                value="3"
                                v-model="selectAll"
                                @change="selectAllItems(3)"
                              >
                                Selecionar todos
                              </b-form-checkbox>
                            </td>
                          </tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_adiantamento"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_adiatamentos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox
                            @change="
                              soma_parcial_saldos(row.item, row.item.check)
                            "
                            value="3"
                            v-model="row.item.check"
                          >
                          </b-form-checkbox>
                        </template>
                        <template #cell(situacao)="row">
                          {{
                            row.item.situacao === 0
                              ? "Aguardando Vinculo Manual"
                              : row.item.situacao === 1
                              ? "Vinculo Manual Realizado"
                              : "Situação Não Conhecida"
                          }}
                        </template>
                        <template #cell(valor_pag)="row">
                          {{ formatterCurrBR(row.item.valor_pag) }}
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-right align-items-right"
                  >
                    <div class="col-md-12 text-right mr-10">
                      <Label
                        ><b-badge
                          data-name="R$"
                          class="soma"
                          variant="success"
                          >{{ formatterCurrBR(soma_adiatamentos) }}</b-badge
                        ></Label
                      >
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Observações">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control text-left"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    abr_modal: { type: Boolean },
    vet_contas: { type: Array },
    fornecedor_obj: { type: Object },
  },
  data() {
    return {
      recarega_tabela: true,
      selectAll: false,
      modal: null,
      vet_fornecedor: [],
      vet_corrente: [],
      vet_adiantamento: [],
      aux_vet_adiatamenotos: [],
      vet_pedidos: [],
      vet_faturamentos: [],
      vet_contas_a_pagar: [],
      fields_itens_faturamento: [
        // {
        //   label: "",
        //   key: "check",
        //   tdClass: "text-center",
        //   thClass: "text-center info",
        // },
        {
          label: "Número",
          key: "nnf",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "situacao",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Faturamento",
          key: "tipo_id",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },
      ],

      fields_itens_pedidos: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center info",
        },
        {
          label: "Número",
          key: "nnf",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "situacao_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Faturamento",
          key: "tipo_id",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens_adiatamentos: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center info",
        },
        {
          label: "Número",
          key: "id",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center ",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Situação",
          key: "situacao",
          tdClass: "text-center ",
          thClass: "text-center",
          variant: "danger",
        },
        {
          label: "Valor",
          key: "valor_pag",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens_corrente: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "pessoa_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Número",
          key: "numero",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          label: "Saldo",
          key: "saldo",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_transf_dest: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Origem",
          key: "origem_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Destino",
          key: "destino_id",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens: [
        {
          label: "Número",
          key: "id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Fatura",
          key: "data_fatura",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "obs",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "PC",
          key: "cod_cupom",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Juros",
          key: "juros",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Desconto",
          key: "desconto",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Total",
          key: "tot",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      soma_adiatamentos: 0,
      array_contas: [],
      objetoFornecedor: null,
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.modal = this.abr_modal;
    this.array_contas = this.vet_contas;
    this.objetoFornecedor = this.fornecedor_obj;
  },
  mounted() {
    this.get_pagamentos_antecipaddos();
    // tenho que fazer a pesquisa pelo id  do fornecedor
    this.get_contas_a_pagar();
    this.get_pedidos();
    this.get_conta_corrente();
    console.log("Mostrando obj fornecedor e vet contas");
    console.log(this.vet_contas);
    console.log(this.fornecedor_obj);
    this.vet_transferencias_origem_destino();
  },
  methods: {
    // aqui é feita a soma toal de
    soma_parcial_saldos(objeto, check) {
      console.log(objeto);
      if (check == 3) {
        this.soma_adiatamentos =
          this.soma_adiatamentos + parseFloat(objeto.valor_pag);
      } else {
        this.soma_adiatamentos =
          this.soma_adiatamentos - parseFloat(objeto.valor_pag);
      }
    },
    getContas(id) {
      let objeto = this.array_contas.find((c) => c.id == id);
      return objeto.pessoa.pes_apelido;
    },
    vet_transferencias_origem_destino() {
      let conta_fornecedor_id = this.objetoFornecedor.conta[0].id;
      // let objeto_conta_fornecedor = this.array_contas.find(
      //   (c) => c.id == conta_fornecedor_id
      // );
      // console.log("Mostrando o Objeto de forneceor com os arryas de transferencias")
      //   this.array_contas = this.vet_contas;
      // this.objetoFornecedor = this.fornecedor_obj;
      console.log(conta_fornecedor_id);
      console.log(this.array_contas);
      console.log(this.objetoFornecedor);
    },
    selectAllItems() {
      this.aux_vet_adiatamenotos = this.vet_adiantamento;
      // var tam = this.aux_vet_adiatamenotos.length;
      // var cont = 0
      // this.vet_adiatamenotos = []
      this.recarega_tabela = false;
      this.soma_adiatamentos = 0;
      // Percorre o vetor e define o estado 'check' de todos os itens com base no estado do checkbox "Selecionar Todos"
      for (let item of this.aux_vet_adiatamenotos) {
        item.check = this.selectAll;
        if (this.selectAll == 3) {
          this.soma_adiatamentos =
            this.soma_adiatamentos + parseFloat(item.valor_pag);
        }
      }
      if (this.selectAll == false) {
        this.soma_adiatamentos = 0;
      }
      setTimeout(() => {
        this.recarega_tabela = true;
      }, 1);
      console.log(this.soma_adiatamentos);
    },
    async get_pagamentos_antecipaddos() {
      try {
        let response = await http.get(
          "/regPag?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_adiantamento = response.data;
          //console.log(this.vet_adiantamento);
        } else {
          this.vet_adiantamento = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_conta_corrente() {
      try {
        let response = await http.get(
          "/corrente?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_corrente = response.data;
          console.log(this.vet_corrente);
        } else {
          this.vet_corrente = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_contas_a_pagar() {
      try {
        let response = await http.get(
          "/pagas?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_contas_a_pagar = response.data;
          //console.log(this.vet_contas_a_pagar);
        } else {
          this.vet_contas_a_pagar = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_pedidos() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_pedidos = response.data;
          this.vet_faturamentos = response.data;
          //console.log(this.vet_pedidos);
        } else {
          this.vet_pedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    fechar_financeiro() {
      this.$emit("fechar_financeiro");
    },
  },
};
</script>
<style scoped>
.soma {
  width: 100px;
  height: 28px;
  font-size: 20px;
}
.custom-table-header th {
  background-color: #3498db; /* Substitua esta cor pelo código da cor desejada */
  color: #ffffff; /* Cor do texto no cabeçalho */
}
</style>