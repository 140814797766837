<template>
  <b-modal
    v-model="modal"
    id="modal-xl"
    size="xl"
    title="Regitstrando Pagamento Fornecedor"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <div
              class="row d-flex justify-content-right align-items-right mb-2"
            >
              <div class="col-md-12 text-right">
                <div>
                  <b-dropdown
                    id="dropdown-dropup"
                    dropup
                    text="Opções"
                    variant="primary"
                    class="m-2"
                  >
                    <b-dropdown-item href="#"
                      >Pagamento não vincuclado a fornecedores</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="abrir_opc()"
                      >Ficha Finaceira do Fornecedor</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      >ir para contas a pagar</b-dropdown-item
                    >
                    <b-dropdown-item href="#">Atualizar</b-dropdown-item>
                  </b-dropdown>
                  <div class="row" v-if="opc_abrir">
                    <div class="col-md-12">
                      <!-- colocar o fichar financeir aqui -->
                      <ficha
                        :abr_modal="opc_abrir"
                        @fechar_financeiro="fechar_financeiro"
                        :vet_contas="contas2"
                        :fornecedor_obj="objeto_for"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <widget1 :Cep="Cep" :cidade="cidade" :fornecedor="fornecedor" />
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="card_abr">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <span class="mr-4"
              >{{ "Saldo -> "
              }}<span class="badge badge-success">{{
                origem_conta.saldo
              }}</span></span
            >
            <span class="mr-4"
              >{{ "Nome -> "
              }}<span class="badge badge-info">{{
                origem_conta.pessoa.pes_apelido
              }}</span></span
            >

            <div class="row" v-if="obj_fornecedor.conta.length > 0">
              <div class="col-md-12">
                <span class="mr-4"
                  >{{ "Saldo Destino -> "
                  }}<span class="badge badge-success">{{
                    obj_fornecedor.conta[0].saldo
                  }}</span></span
                >
                <span class="mr-4"
                  >{{ "Nome Destino-> "
                  }}<span class="badge badge-info">{{
                   obj_fornecedor.conta[0].pessoa.pes_apelido
                  }}</span></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.iten variant="light">itens: </b-button>
      </b-card-header>
      <b-collapse id="iten" accordion="pedido-accordion" role="tabpanel">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th class="b-th">&nbsp;</b-th>
                    <b-th>&nbsp;</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <tr>
                    <td>
                      <b-form-checkbox value="3">
                        Selecionar todos
                      </b-form-checkbox>
                    </td>
                  </tr>
                </b-tbody>
              </b-table-simple>
              <b-table
                head-variant="dark"
                striped
                hover
                :items="vet_fornecedor"
                responsive
                id="doc-itens"
                :fields="fields_itens"
              >
                <template #cell(check)="row">
                  <!-- <span>{{row.item.vecimento}}</span> -->
                  <b-form-checkbox value="3" v-model="row.item.check">
                  </b-form-checkbox>
                  <!-- <input
                  type="radio"
                  class="estilo_input1"
                /> -->
                </template>
              </b-table>
              <b-table
                head-variant="dark"
                striped
                hover
                :items="vet_fornecedor_soma"
                responsive
                id="doc-itens"
                :fields="fields_itens1"
              >
              </b-table>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <Label>Total Selecionado:</Label>
            <input
              v-model="reg_pag.tot"
              type="text"
              name=""
              id=""
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>Valor Pago:</Label>
            <input
              @input="formatInput_valor()"
              v-model="reg_pag.valor_pag"
              type="text"
              name=""
              id=""
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>Data do Pagamento:</Label>
            <input
              v-model="reg_pag.data"
              type="date"
              name=""
              id=""
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>{{ "*Espécie:" }}</Label>
            <!-- reg_pag.especie -->
            <multiselect
              v-model="select_especie"
              placeholder="Selecione"
              label="tiponome"
              track-by="tiponome"
              :options="especie_pagamentos"
              :option-height="104"
              :custom-label="customLabelEspecie"
              :show-labels="false"
              deselect-label="ENTER para remover"
              select-label="Pressione ENTER"
              @select="seleciona_especie($event)"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.id }} - {{ props.option.tiponome }}</span
                  >
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.id }} - {{ props.option.tiponome }}</span
                  >
                </div> </template
              ><span slot="noResult">Opa! Nenhum registro encontrado</span>
            </multiselect>
            <!-- <input
              v-model="reg_pag.especie"
              type="text"
              name=""
              id=""
              class="form-control text-left"
            /> -->
          </div>
          <div class="col-md-4">
            <Label>N° doc:</Label>

            <input
              v-model="reg_pag.n_doc"
              type="text"
              name=""
              id=""
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>Conta(Saldo):</Label>
            <multiselect
              v-model="select_contasaldo"
              @select="seleciona_conta_saldo($event)"
              :options="vet_contas"
              :custom-label="getApelido"
            >
            </multiselect>
          </div>
          <div class="col-md-12">
            <Label>Descirção:</Label>
            <textarea
              v-model="reg_pag.desc"
              name=""
              id=""
              cols="30"
              rows="2"
              class="form-control text-left"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click="post_reg_pag()">
              Pagar
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import widget1 from "./widget_reg.vue";
import Multiselect from "vue-multiselect";
import ficha from "./fichafinanceira.vue";
export default {
  props: {
    abr_modal: { type: Boolean },
    Cep: { type: String },
    cidade: { type: String },
    fornecedor: { type: String },
    objeto_for: { type: Object },
    pagamentos: { type: Array },
    caixas: { type: Array },
    contas2: { type: Array },
  },
  components: {
    widget1,
    Multiselect,
    ficha,
  },
  data() {
    return {
      card_abr: false,
      origem_conta: {},
      vet_contas: [],
      select_especie: null,
      select_contasaldo: null,
      title: "registrando pagamento",
      modal: null,
      objeto: null,
      especie_pagamentos: [],
      abrir_widget: false,
      vet_fornecedor_soma: [
        {
          valor: 100,
          juros: 2,
          desconto: 10,
          tot: 10,
        },
      ],
      opc_abrir: false,
      // valores estaticos;
      vet_fornecedor: [
        {
          check: "",
          num: 1,
          fat: "fat",
          desc: "fautra1",
          pc: "contas a receber",
          valor: 100,
          juros: 2,
          desconto: 10,
          tot: 10,
        },
        {
          check: "",
          num: 2,
          fat: "fat2",
          desc: "fautra2",
          pc: "contas a receber",
          valor: 100,
          juros: 2,
          desconto: 10,
          tot: 10,
        },
      ],
      contas: ["conta1", "conta2"],
      fields_itens: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Número",
          key: "num",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Fatura",
          key: "fat",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "PC",
          key: "pc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Juros",
          key: "juros",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Desconto",
          key: "desconto",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Total",
          key: "tot",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens1: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "  ",
          key: "num",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "  ",
          key: "fat",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: " ",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "",
          key: "pc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Juros",
          key: "juros",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Desconto",
          key: "desconto",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Total",
          key: "tot",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      reg_pag: {
        tot: 1,
        valor_pag: 1,
        data: "2023-02-02",
        especie: 1,
        n_doc: "12323",
        conta_saldo: 22,
        desc: "olasd",
        pessoa_id: null,
        situacao: 0,
      },
      caixas_conta: [],
      valores_pagos: [],
      obj_fornecedor: null,
    };
  },
  mounted() {
    //console.log(this.cidade);
    this.abrir_componet();
    //console.log(this.caixas_conta)
    this.caixa_empresa();
    console.log("Objeto Fornecedor");
    console.log(this.objeto_for);
    this.reg_pag.pessoa_id = this.objeto_for.id;
  },
  created() {
    this.modal = this.abr_modal;
    this.especie_pagamentos = this.pagamentos;
    this.vet_contas = this.contas2;
    this.obj_fornecedor = this.objeto_for;
  },

  methods: {
    getApelido(option) {
      return option.pessoa.pes_apelido;
    },
    seleciona_especie(event) {
      console.log(event.id);
      this.reg_pag.especie = event.id;
    },
    seleciona_conta_saldo(event) {
      console.log(event.id);
      this.reg_pag.conta_saldo = event.id;
      console.log("Mostrando Saldo conta");
      console.log(event);
      this.origem_conta = event;
      if (this.origem_conta) {
        this.card_abr = true;
      } else {
        this.card_abr = false;
      }
    },
    add_valores_pagos() {
      this.valores_pagos.push(this.reg_pag.valor_pag);
    },
    // criar um vetor com as empreas assoicadas ao caixa
    caixa_empresa() {
      this.caixas.forEach((caixa) => {
        this.caixas_conta.push(caixa.empresa);
      });
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.reg_pag.valor_pag.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.reg_pag.valor_pag = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    customLabelEspecie({ id, tiponome }) {
      return `${id} – ${tiponome}`;
    },
    abrir_opc() {
      console.log("entrou no true");
      this.opc_abrir = true;
    },
    fechar_financeiro() {
      this.opc_abrir = false;
    },
    abrir_componet() {
      this.abrir_widget = true;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    post_reg_pag() {
      this.coverter_float();
      // passo o objeto do lado do nome , coloando uma virgula;
      // origem_conta.saldo

      if (this.obj_fornecedor.conta.length == 0) {
        this.makeToast(
          "danger",
          "Conta do fornecedor Não exisste Crie uma Conta"
        );
      } else {
        if (
          this.reg_pag.valor_pag > this.origem_conta.saldo &&
          this.origem_conta != undefined
        ) {
          this.makeToast(
            "danger",
            "Valor Digitado Mario que o Saldo Disponivel"
          );
        } else {
          this.reg_pag.conta_destino =  this.obj_fornecedor.conta[0].id
          this.$emit("post_reg_pag", this.reg_pag);
        }
      }
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.reg_pag.valor_pag == "string") {
        this.item_abastece2 = this.reg_pag;
        let stringValor = this.item_abastece2.valor_pag;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.reg_pag.valor_pag = valorFlutuante;
      }
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>
<style scoped>
#font {
  font-size: 10px;
}
</style>>
